.infoText{
    margin-left: 2%;
}
.InfoHeader{
    white-space: pre-wrap;
    width:50%;
}
.InfoContent{
    white-space: pre-wrap;
    width:70%;
}