.infoText{
    margin-left: 2%;
}
.InfoHeader{
    white-space: pre-wrap;
    width:50%;
}
.InfoContent{
    white-space: pre-wrap;
    width:40%;
}

.infoTextMobile{
    margin-left: 2%;
}
.InfoHeaderMobile{
word-wrap: break-word;
    width:10%;
    max-width: 50px;
}
.InfoContentMobile{
    word-wrap: break-word;
    width:10%;
    max-width: 300px;
}
.paymentTableHeader{
    width: 50%;
    text-align: left;
    font-size: 20px;
    font-weight: bold;
}
.paymentTableRow{
    text-align: left;
    font-size: 20px;
}
.paymentTableButton{
    text-align: left;
    font-size: 20px;
}