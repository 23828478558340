.NavigationButtonStyle {
  background-color: #419de0;
  border: none;
  color: #000000;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 18px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;

}

.NavigationButton:hover {
  background-color: #214222;
  color: #771e1e;
}