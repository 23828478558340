.events {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    min-height: 64px;
    padding: 8px;
    align-items: flex-start;
    overflow: hidden;
    width: 100%;
    box-sizing: border-box;
}

/* Target the direct container of the event items */
.events > div {
    display: flex !important;
    gap: 4px !important;
    flex-wrap: wrap !important;
    width: 100% !important;
}

/* Target individual event items if needed */
.events > div > * {
    margin: 0 !important;
    flex: 0 0 auto !important;
}