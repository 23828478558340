.main {
    min-width:100%
}

.registerBox {
    font-size: 1.6rem;
    display: grid;
    min-height: 200px;
    padding: 1rem;
    overflow-wrap: break-word;
    margin-left: 0;
    margin-right: auto;
    min-width: 100%;
}


.inputField{
    width: 70%;
    border-radius: 10px;
    position: relative;
    background-color: rgba(255,255,255,0.3);
    transition: 0.3s all;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 20px;
}
.MemberInfoHeader{
    white-space: pre-wrap;
    width:50%;
    font-size: 15px;
}
.MemberInfoContent{
    white-space: pre-wrap;
    overflow-wrap: break-word;
    width:70%;
    font-size: 15px;
}
.RegisterpaymentTableHeader
{
    font-size: 16px;
    font-weight: bold;
}
.RegisterpaymentTableRow
{
    font-size: 16px;
}

.RegisterpaymentAccountInfoTableHeader
{
    font-size: 13px;
    font-weight: bold;
    width: 35%;
}
.RegisterpaymentAccountTableRow
{
    font-size: 13px;
}

.PaymentImageIcon
{
    max-height: 30px;
    margin-right: 10px;
}

.PaymentImageContainer
{
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    padding-bottom: 10px; /* Add some padding to prevent scrollbar clipping */
}