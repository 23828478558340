.groupImage {
    height: auto;
    width: 50%;
    object-fit: contain;
    display:block;
    min-width: 100%;

}

.groupImageMobile {
    height: auto;
    width: 100%;
    object-fit: contain;
    display:block;
    margin-top: -20%;
    margin-bottom: 1em;
    min-width: 70%;
    margin-left: auto;
    margin-right: auto;

}

.compImage {
    height: auto;
    width: 100%;

}
.compImageMobile {
    height: auto;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

}
.HomeParagraph {
    font-size: 1.0em;
    text-align: left;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 5%;
    margin-right: -20%;
    max-width: 50%;
    min-width: 40%;
}

.HomeParagraphMobile {
    font-size: 1.0em;
    text-align: left;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 5%;
    max-width: 80%;
    min-width: 60%;
}
@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}


.ImageTextBlock{
    display: flex;
    margin-left: -40%;
}
.ParagraphHeader{
    font-size: 3em;
    text-align: left;
    font-family: "Times New Roman";
}
.ParagraphHeaderMobile{
    font-size: 3em;
    text-align: left;
    font-family: "Times New Roman";
}
.ParagraphContent{
    font-size: 1em;
    text-align: left;
    font-family: Open Sans, sans-serif;
}
.ParagraphContentMobile{
    font-size: 1em;
    text-align: left;
    font-family: "Arial";
}
.Home{
    flex-direction: column;
    display: flex;
}
.HomeMobile{
    flex-direction: column;
    display: flex;
}


