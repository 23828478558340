.parallax-container {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.parallax-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../image/Backdrop.png');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    z-index: -1;
    opacity: 0; /* Initially hidden */
    animation: fadeInAnimation 0.5s ease-in 0.5s forwards; /* 1s delay, 1s duration, ease-in timing, forwards to keep the final state */
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0; /* Start with opacity 0 */
    }
    100% {
        opacity: 1; /* End with opacity 1 (fully visible) */
    }
}

@media (max-width: 1090px) {
    .parallax-container {
        background-image: url('../image/banner_mobile.jpg');
        background-attachment: scroll;
        background-position: center;
        padding-bottom: 20.67%;
        background-size: cover;
    }

    .parallax-content {
        height: 100vh;
        position: relative;
        overflow: hidden;
    }

}

.parallax-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.content-container {
    padding: 50px;
}

.content-section {
    margin-bottom: 40px;
    margin-top: 40px;
}

.slide-container {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.slide-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url('../image/putrajaya_backdrop.jpg');
    background-size: cover;
    background-position: 50% 50%;
    background-attachment: fixed;
    z-index: -1;
}
@media (max-width: 767px) {
    .slide-container {
        background-image: url('../image/putrajaya_backdrop.jpg');
        background-attachment: scroll;
        background-position: center;
        padding-bottom: 50.67%;
        background-size: cover;
    }
}
.slider-mobile {
    padding: 50px;
    text-align: center;
    opacity: 0;
    transform: translateY(200px);
    transition: opacity 1s, transform 2s;
    margin-top: 40%;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.slider-mobile.visible {
    opacity: 1;
    transform: translateY(0);
}

.slide-content {
    position: sticky;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 50%;
    text-align: center;
    color: white;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.spacer {
    height: 100px;
}

.info-section {
    padding: 50px;
    text-align: center;
    opacity: 0;
    transform: translateY(200px);
    transition: opacity 1s, transform 2s;

}

.info-section.visible {
    opacity: 1;
    transform: translateY(0);
}

html {
    scroll-behavior: smooth;
}

.sponsor-section {
    padding: 50px;
    text-align: center;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s, transform 0.5s;
}

.sponsor-section.visible {
    opacity: 1;
    transform: translateY(0);
}

.sponsor-images {
    display: flex;
    justify-content: center;
    align-items: center;
}


.sponsor-images img {
    max-height:  200px;
    max-width:  300px;
    margin: 0 40px; /* Increase the margin */
    margin-top: 10px;
    margin-bottom:5%
}

.venue-images img {
    max-width: 300px; /* Increase the max-width */
    margin: 0 40px; /* Increase the margin */
    margin-top: 10px;
}

@media (max-width: 767px) {
    .sponsor-images {
        flex-direction: column;
    }

    .sponsor-images img {
        max-height:  200px;
        max-width:  300px;
        margin: 0 40px; /* Increase the margin */
        margin-top: 10px;
        margin-bottom:5%;
    }
}